import React, { useState } from "react";
import "../style/Pendaftaran.css";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    nama: "",
    jenisKelamin: "",
    tempatLahir: "",
    tanggalLahir: "",
    provinsi: "",
    kabupaten: "",
    kecamatan: "",
    desa: "",
    handphone: "",
    email: "",
    agama: "",
    institusi: "",
    jabatan: "",
    hobi: "",
    citaCita: "",
    motivasi: "",
  });

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    try {
      const response = await fetch("https://yac.respsourcing.com/participants.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result.status === "success") {
        setMessage("Pendaftaran berhasil! Kami akan menghubungi Anda segera.");
        setFormData({
          nama: "",
          jenisKelamin: "",
          tempatLahir: "",
          tanggalLahir: "",
          provinsi: "",
          kabupaten: "",
          kecamatan: "",
          desa: "",
          handphone: "",
          email: "",
          agama: "",
          institusi: "",
          jabatan: "",
          hobi: "",
          citaCita: "",
          motivasi: "",
        });
      } else {
        setMessage(`Pendaftaran gagal: ${result.message}`);
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setMessage("Terjadi kesalahan selama pendaftaran. Silakan coba lagi.");
    }
  };

  return (
    <div className="registration-form">
      <h2>Pendaftaran Peserta Youth Agripreneur Camp</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="nama"
          placeholder="Nama Lengkap"
          value={formData.nama}
          onChange={handleChange}
          required
        />
        <select
          name="jenisKelamin"
          value={formData.jenisKelamin}
          onChange={handleChange}
          required
        >
          <option value="">Jenis Kelamin</option>
          <option value="Laki-Laki">Laki-Laki</option>
          <option value="Perempuan">Perempuan</option>
        </select>
        <input
          type="text"
          name="tempatLahir"
          placeholder="Tempat Lahir"
          value={formData.tempatLahir}
          onChange={handleChange}
          required
        />
        <input
          type="date"
          name="tanggalLahir"
          placeholder="Tanggal Lahir"
          value={formData.tanggalLahir}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="provinsi"
          placeholder="Provinsi"
          value={formData.provinsi}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="kabupaten"
          placeholder="Kabupaten"
          value={formData.kabupaten}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="kecamatan"
          placeholder="Kecamatan"
          value={formData.kecamatan}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="desa"
          placeholder="Desa"
          value={formData.desa}
          onChange={handleChange}
          required
        />
        <input
          type="tel"
          name="handphone"
          placeholder="Nomor Handphone"
          value={formData.handphone}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="agama"
          placeholder="Agama"
          value={formData.agama}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="institusi"
          placeholder="Institusi"
          value={formData.institusi}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="jabatan"
          placeholder="Jabatan"
          value={formData.jabatan}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="hobi"
          placeholder="Hobi"
          value={formData.hobi}
          onChange={handleChange}
        />
        <input
          type="text"
          name="citaCita"
          placeholder="Cita-Cita"
          value={formData.citaCita}
          onChange={handleChange}
        />
        <textarea
          name="motivasi"
          placeholder="Motivasi mengikuti Youth Agripreneur Camp"
          value={formData.motivasi}
          onChange={handleChange}
          required
        ></textarea>
        <button type="submit" className="btn-primary">Daftar</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default RegistrationForm;
