import React, { useState } from "react";
import "../style/SurveyMaker.css";

const SurveyMaker = () => {
  const [questions, setQuestions] = useState([]); // Daftar pertanyaan
  const [currentQuestion, setCurrentQuestion] = useState({
    type: "text",
    label: "",
    options: [],
  });
  const [surveyTitle, setSurveyTitle] = useState(""); // Judul survei
  const [isLoading, setIsLoading] = useState(false); // Indikator loading

  const [showLogicForm, setShowLogicForm] = useState(false); // Logika pertanyaan
  const [logic, setLogic] = useState({
    questionIndex: null,
    condition: "",
    value: "",
    targetQuestionIndex: null,
  });

  // Tambahkan pertanyaan
  const addQuestion = () => {
    if (!currentQuestion.label.trim()) {
      alert("Pertanyaan tidak boleh kosong");
      return;
    }

    if (["select_one", "select_multiple"].includes(currentQuestion.type)) {
      const validOptions = currentQuestion.options.filter(
        (option) => option.trim() !== ""
      );
      if (validOptions.length === 0) {
        alert("Opsi tidak boleh kosong untuk pertanyaan tipe pilihan");
        return;
      }
      currentQuestion.options = validOptions;
    }

    setQuestions([...questions, { ...currentQuestion, logic: [] }]);
    setCurrentQuestion({
      type: "text",
      label: "",
      options: [],
    });
  };

  // Tambahkan logika
  const addLogic = () => {
    const updatedQuestions = [...questions];
    const question = updatedQuestions[logic.questionIndex];

    if (!question) {
      alert("Pertanyaan untuk logika tidak ditemukan.");
      return;
    }

    question.logic.push({
      condition: logic.condition,
      value: logic.value,
      targetQuestionIndex: logic.targetQuestionIndex,
    });
    setQuestions(updatedQuestions);
    setShowLogicForm(false);
    setLogic({
      questionIndex: null,
      condition: "",
      value: "",
      targetQuestionIndex: null,
    });
  };

  // Simpan survei ke backend
  const saveSurvey = async () => {
    if (isLoading) return; // Hindari pemanggilan berulang
    if (!surveyTitle.trim()) {
      alert("Judul survei tidak boleh kosong");
      return;
    }

    if (questions.length === 0) {
      alert("Tambahkan minimal satu pertanyaan sebelum menyimpan");
      return;
    }

    const surveyData = {
      title: surveyTitle,
      questions,
    };

    console.log("Payload yang dikirim:", surveyData);

    setIsLoading(true);
    try {
      const response = await fetch("https://yac.respsourcing.com/save_survey.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(surveyData),
      });

      if (response.ok) {
        const result = await response.json();
        alert(result.message);
        setSurveyTitle(""); // Reset title
        setQuestions([]); // Reset questions
      } else {
        const error = await response.json();
        console.error("Error dari server:", error);
        alert("Gagal menyimpan survei: " + error.message);
      }
    } catch (error) {
      console.error("Kesalahan saat menyimpan survei:", error);
      alert("Terjadi kesalahan. Silakan coba lagi.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="survey-maker">
      {/* Input judul survei */}
      <div className="survey-title">
        <label>
          Judul Survei:
          <input
            type="text"
            value={surveyTitle}
            onChange={(e) => setSurveyTitle(e.target.value)}
            placeholder="Masukkan judul survei..."
          />
        </label>
      </div>

      {/* Form pembuatan pertanyaan */}
      <div className="question-creator">
        <label>
          Buat Pertanyaan:
          <input
            type="text"
            value={currentQuestion.label}
            onChange={(e) =>
              setCurrentQuestion({ ...currentQuestion, label: e.target.value })
            }
            placeholder="Masukkan pertanyaan..."
          />
        </label>
        <label>
          Jenis Pertanyaan:
          <select
            value={currentQuestion.type}
            onChange={(e) =>
              setCurrentQuestion({ ...currentQuestion, type: e.target.value })
            }
          >
            <option value="text">Text</option>
            <option value="date">Date</option>
            <option value="select_one">Select One</option>
            <option value="select_multiple">Select Multiple</option>
            <option value="integer">Integer</option>
            <option value="decimal">Decimal</option>
            <option value="photo">Photo</option>
          </select>
        </label>

        {/* Opsi untuk select_one dan select_multiple */}
        {["select_one", "select_multiple"].includes(currentQuestion.type) && (
          <div className="options">
            <h3>Opsi:</h3>
            {currentQuestion.options.map((option, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="text"
                  value={option}
                  onChange={(e) =>
                    setCurrentQuestion({
                      ...currentQuestion,
                      options: currentQuestion.options.map((opt, i) =>
                        i === index ? e.target.value : opt
                      ),
                    })
                  }
                  placeholder={`Opsi ${index + 1}`}
                />
                <button
                  type="button"
                  onClick={() =>
                    setCurrentQuestion({
                      ...currentQuestion,
                      options: currentQuestion.options.filter((_, i) => i !== index),
                    })
                  }
                >
                  Hapus
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() =>
                setCurrentQuestion({
                  ...currentQuestion,
                  options: [...currentQuestion.options, ""],
                })
              }
            >
              Tambahkan Opsi
            </button>
          </div>
        )}

        <button onClick={addQuestion} className="btn-primary">
          Tambahkan Pertanyaan
        </button>
      </div>

      {/* Logika pertanyaan */}
      <div className="logic-creator">
        <button onClick={() => setShowLogicForm(true)} className="btn-secondary">
          Tambahkan Logika
        </button>

        {showLogicForm && (
          <div className="logic-form">
            <label>
              Pertanyaan:
              <select
                value={logic.questionIndex}
                onChange={(e) =>
                  setLogic({ ...logic, questionIndex: Number(e.target.value) })
                }
              >
                <option value="">Pilih Pertanyaan</option>
                {questions.map((q, index) => (
                  <option key={index} value={index}>
                    {q.label}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Kondisi:
              <input
                type="text"
                value={logic.condition}
                onChange={(e) =>
                  setLogic({ ...logic, condition: e.target.value })
                }
                placeholder="Masukkan kondisi logika..."
              />
            </label>
            <label>
              Nilai:
              <input
                type="text"
                value={logic.value}
                onChange={(e) => setLogic({ ...logic, value: e.target.value })}
                placeholder="Masukkan nilai kondisi..."
              />
            </label>
            <label>
              Target Pertanyaan:
              <select
                value={logic.targetQuestionIndex}
                onChange={(e) =>
                  setLogic({
                    ...logic,
                    targetQuestionIndex: Number(e.target.value),
                  })
                }
              >
                <option value="">Pilih Target</option>
                {questions.map((q, index) => (
                  <option key={index} value={index}>
                    {q.label}
                  </option>
                ))}
              </select>
            </label>
            <button onClick={addLogic} className="btn-primary">
              Simpan Logika
            </button>
            <button
              onClick={() => setShowLogicForm(false)}
              className="btn-secondary"
            >
              Batal
            </button>
          </div>
        )}
      </div>

      {/* Daftar pertanyaan */}
      <div className="question-list">
        <h3>Daftar Pertanyaan:</h3>
        {questions.length === 0 ? (
          <p>Belum ada pertanyaan.</p>
        ) : (
          <ul>
            {questions.map((q, index) => (
              <li key={index}>
                <strong>{q.label}</strong> ({q.type})
                {q.options?.length > 0 && (
                  <ul>
                    {q.options.map((opt, i) => (
                      <li key={i}>{opt}</li>
                    ))}
                  </ul>
                )}
                {q.logic?.length > 0 && (
                  <ul>
                    {q.logic.map((l, i) => (
                      <li key={i}>
                        Jika {q.label} {l.condition} {l.value}, maka tampilkan
                        pertanyaan {questions[l.targetQuestionIndex]?.label}.
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Tombol Simpan */}
      <button onClick={saveSurvey} disabled={isLoading} className="btn-primary">
        {isLoading ? "Menyimpan..." : "Simpan Survei"}
      </button>
    </div>
  );
};

export default SurveyMaker;
