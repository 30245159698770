import React, { useState, useEffect } from "react";
import "../style/BatchYAC.css";

const BatchYAC = () => {
  const [batches, setBatches] = useState([]); // State untuk data batch
  const [formData, setFormData] = useState({
    batch: "",
    kabupaten: "",
    mitra: "",
    tanggal_mulai: "",
    tanggal_berakhir: "",
    jumlah_peserta: "",
    status: "",
  });
  const [editId, setEditId] = useState(null); // State untuk id batch yang sedang diedit
  const [showPopup, setShowPopup] = useState(false); // State untuk mengontrol visibilitas popup
  const [isLoading, setIsLoading] = useState(false); // State untuk menampilkan loading

  // Fetch data batch dari server
  const fetchBatches = async () => {
    try {
      const response = await fetch("https://yac.respsourcing.com/batch_yac.php");
      const data = await response.json();
      if (Array.isArray(data)) {
        setBatches(data);
      } else {
        setBatches([]);
      }
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  useEffect(() => {
    fetchBatches(); // Panggil saat komponen dimount
  }, []);

  // Fungsi untuk menangani perubahan input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Fungsi untuk membuka form tambah/edit batch
  const openForm = (batch = null) => {
    if (batch) {
      setFormData({
        ...batch,
        jumlah_peserta: batch.jumlah_peserta.toString(), // Pastikan jumlah_peserta dalam format string untuk input form
      });
      setEditId(batch.id);
    } else {
      setFormData({
        batch: "",
        kabupaten: "",
        mitra: "",
        tanggal_mulai: "",
        tanggal_berakhir: "",
        jumlah_peserta: "",
        status: "",
      });
      setEditId(null);
    }
    setShowPopup(true);
  };

  // Fungsi untuk menutup popup form
  const closeForm = () => {
    setShowPopup(false);
    setFormData({
      batch: "",
      kabupaten: "",
      mitra: "",
      tanggal_mulai: "",
      tanggal_berakhir: "",
      jumlah_peserta: "",
      status: "",
    });
    setEditId(null);
  };

  // Fungsi untuk submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      id: editId ? parseInt(editId, 10) : undefined,
      batch: formData.batch,
      kabupaten: formData.kabupaten,
      mitra: formData.mitra,
      tanggal_mulai: formData.tanggal_mulai,
      tanggal_berakhir: formData.tanggal_berakhir,
      jumlah_peserta: parseInt(formData.jumlah_peserta, 10),
      status: formData.status,
    };

    console.log("Payload sent to server:", payload);

    try {
      const response = await fetch("https://yac.respsourcing.com/batch_yac.php", {
        method: editId ? "PUT" : "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseBody = await response.json();
      console.log("Response body:", responseBody);

      if (response.ok && responseBody.status === "success") {
        alert(editId ? "Batch berhasil diperbarui!" : "Batch berhasil ditambahkan!");

        // Update state batches secara lokal agar data langsung diperbarui di tabel
        if (editId) {
          setBatches((prevBatches) =>
            prevBatches.map((batch) =>
              batch.id === editId ? { ...batch, ...payload } : batch
            )
          );
        } else {
          fetchBatches(); // Refresh tabel data jika menambah batch baru
        }
        closeForm(); // Reset form dan tutup popup
      } else {
        alert(`Gagal menyimpan data batch: ${responseBody.message}`);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      alert("Terjadi kesalahan saat menyimpan data. Silakan coba lagi.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="batch-yac">
      <button className="btn-primary" onClick={() => openForm()}>
        Buat Batch Baru
      </button>

      {/* Tabel Batch */}
      <table className="batch-table">
        <thead>
          <tr>
            <th>Batch</th>
            <th>Kabupaten</th>
            <th>Mitra</th>
            <th>Tanggal Mulai</th>
            <th>Tanggal Berakhir</th>
            <th>Jumlah Peserta</th>
            <th>Status</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {batches.map((batch) => (
            <tr key={batch.id}>
              <td>{batch.batch}</td>
              <td>{batch.kabupaten}</td>
              <td>{batch.mitra}</td>
              <td>{batch.tanggal_mulai}</td>
              <td>{batch.tanggal_berakhir}</td>
              <td>{batch.jumlah_peserta}</td>
              <td>{batch.status}</td>
              <td>
                <button className="btn-edit" onClick={() => openForm(batch)}>
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Popup Form */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <form onSubmit={handleSubmit} className="popup-form">
              <h3>{editId ? "Update Batch" : "Tambah Batch Baru"}</h3>
              <label>Batch:</label>
              <select name="batch" value={formData.batch} onChange={handleChange} required>
                <option value="">Pilih Batch</option>
                <option value="Satu">Satu</option>
                <option value="Dua">Dua</option>
                <option value="Tiga">Tiga</option>
                <option value="Empat">Empat</option>
                <option value="Lima">Lima</option>
              </select>
              <label>Kabupaten:</label>
              <input
                type="text"
                name="kabupaten"
                value={formData.kabupaten}
                onChange={handleChange}
                required
              />
              <label>Mitra:</label>
              <input
                type="text"
                name="mitra"
                value={formData.mitra}
                onChange={handleChange}
                required
              />
              <label>Tanggal Mulai:</label>
              <input
                type="date"
                name="tanggal_mulai"
                value={formData.tanggal_mulai}
                onChange={handleChange}
                required
              />
              <label>Tanggal Berakhir:</label>
              <input
                type="date"
                name="tanggal_berakhir"
                value={formData.tanggal_berakhir}
                onChange={handleChange}
                required
              />
              <label>Jumlah Peserta:</label>
              <input
                type="number"
                name="jumlah_peserta"
                value={formData.jumlah_peserta}
                onChange={handleChange}
                required
              />
              <label>Status:</label>
              <select name="status" value={formData.status} onChange={handleChange} required>
                <option value="">Pilih Status</option>
                <option value="Selesai">Selesai</option>
                <option value="Terjadwal">Terjadwal</option>
                <option value="Mundur">Mundur</option>
                <option value="Tertunda">Tertunda</option>
              </select>
              <div className="form-actions">
                <button type="submit" className="btn-primary" disabled={isLoading}>
                  {isLoading ? "Menyimpan..." : editId ? "Update Batch" : "Tambah Batch"}
                </button>
                <button type="button" className="btn-secondary" onClick={closeForm}>
                  Batal
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BatchYAC;
