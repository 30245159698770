import React, { useState, useEffect } from "react";
import "../style/SurveyList.css";

const SurveyList = () => {
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        const response = await fetch("https://yac.respsourcing.com/get_survey.php");
        const text = await response.text();
        const data = JSON.parse(text);

        if (data.status === "success" && Array.isArray(data.data)) {
          console.log("Valid Surveys Data:", data.data);
          setSurveys(data.data);
        } else {
          console.error("Invalid API Response:", data.message || "No surveys found.");
        }
      } catch (error) {
        console.error("Error fetching surveys:", error);
      }
    };

    fetchSurveys();
  }, []);

  const handleSurveySelection = (surveyId) => {
    const parsedId = parseInt(surveyId, 10);
    console.log("Dropdown Survey ID (parsed):", parsedId);

    const survey = surveys.find((s) => parseInt(s.id, 10) === parsedId);

    if (survey) {
      console.log("Selected Survey:", survey);
      setSelectedSurvey(survey);
      setResponses([]);
    } else {
      console.error(`Survey not found: ${parsedId}`);
      console.log("Available Surveys IDs:", surveys.map((s) => s.id));
    }
  };

  const handleResponseChange = (questionId, value) => {
    setResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      const index = updatedResponses.findIndex((r) => r.question_id === questionId);

      if (index > -1) {
        updatedResponses[index].answer = value;
      } else {
        updatedResponses.push({ question_id: questionId, answer: value });
      }

      return updatedResponses;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const payload = {
        survey_id: selectedSurvey.id,
        responses,
      };

      console.log("Payload yang dikirim:", payload);

      const response = await fetch("https://yac.respsourcing.com/submit_survey.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      console.log("Hasil dari server:", result);

      if (response.ok) {
        alert("Survey berhasil disimpan!");
      } else {
        alert(`Gagal menyimpan survey: ${result.message}`);
      }
    } catch (error) {
      console.error("Error submitting survey:", error);
      alert("Terjadi kesalahan saat mengirim survey.");
    }
  };

  return (
    <div className="container">
      <p>Silakan pilih survey yang ingin Anda isi:</p>

      {/* Dropdown untuk memilih survei */}
      <select onChange={(e) => handleSurveySelection(e.target.value)} defaultValue="">
        <option value="" disabled>
          Pilih Survey
        </option>
        {surveys.map((survey) => (
          <option key={survey.id} value={survey.id}>
            {survey.survey_title}
          </option>
        ))}
      </select>

      {/* Form pertanyaan survei */}
      {selectedSurvey && selectedSurvey.questions && (
        <div className="survey-form">
          <h2>{selectedSurvey.survey_title}</h2>
          <form onSubmit={handleSubmit}>
            {selectedSurvey.questions.map((question) => (
              <div key={question.id} className="form-group">
                <label>
                  {question.label}
                  {question.required && <span style={{ color: "red" }}> *</span>}
                </label>

                {/* Text Input */}
                {question.type === "text" && (
                  <input
                    type="text"
                    value={responses.find((r) => r.question_id === question.id)?.answer || ""}
                    onChange={(e) => handleResponseChange(question.id, e.target.value)}
                    required={question.required}
                  />
                )}

                {/* Integer Input */}
                {question.type === "integer" && (
                  <input
                    type="number"
                    step="1"
                    value={responses.find((r) => r.question_id === question.id)?.answer || ""}
                    onChange={(e) => handleResponseChange(question.id, e.target.value)}
                    required={question.required}
                  />
                )}

                {/* Date Input */}
                {question.type === "date" && (
                  <input
                    type="date"
                    value={responses.find((r) => r.question_id === question.id)?.answer || ""}
                    onChange={(e) => handleResponseChange(question.id, e.target.value)}
                    required={question.required}
                  />
                )}

                {/* Select One */}
                {question.type === "select_one" && (
                  <select
                    value={responses.find((r) => r.question_id === question.id)?.answer || ""}
                    onChange={(e) => handleResponseChange(question.id, e.target.value)}
                    required={question.required}
                  >
                    <option value="" disabled>
                      Pilih
                    </option>
                    {question.options.map((option, idx) => (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            ))}

            <button type="submit" className="btn-primary">
              Kirim Jawaban
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default SurveyList;
