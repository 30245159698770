import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx"; // Library untuk Export ke Excel
import "../style/ListPeserta.css";

const ListPeserta = () => {
  const [participants, setParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [filters, setFilters] = useState({
    jenisKelamin: "",
    kabupaten: "",
    kecamatan: "",
    desa: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://yac.respsourcing.com/participants.php");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setParticipants(data.participants);
        setFilteredParticipants(data.participants); // Default data
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  // Handle filter change
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });

    // Reset dependent filters when parent filter changes
    if (name === "kabupaten") {
      setFilters((prev) => ({ ...prev, kecamatan: "", desa: "" }));
    } else if (name === "kecamatan") {
      setFilters((prev) => ({ ...prev, desa: "" }));
    }
  };

  // Apply filters when Filter button is clicked
  const applyFilters = () => {
    let filteredData = participants;

    if (filters.jenisKelamin) {
      filteredData = filteredData.filter(
        (p) => p.jenis_kelamin === filters.jenisKelamin
      );
    }
    if (filters.kabupaten) {
      filteredData = filteredData.filter(
        (p) => p.kabupaten === filters.kabupaten
      );
    }
    if (filters.kecamatan) {
      filteredData = filteredData.filter(
        (p) => p.kecamatan === filters.kecamatan
      );
    }
    if (filters.desa) {
      filteredData = filteredData.filter((p) => p.desa === filters.desa);
    }

    setFilteredParticipants(filteredData);
  };

  // Reset filters and show all data
  const resetFilters = () => {
    setFilters({
      jenisKelamin: "",
      kabupaten: "",
      kecamatan: "",
      desa: "",
    });
    setFilteredParticipants(participants); // Reset to original data
  };

  // Export to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredParticipants);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Participants");
    XLSX.writeFile(workbook, "Participants.xlsx");
  };

  // Get unique values for dropdown options, optionally filtered by parent key
  const getUniqueValues = (key, filterKey = null, filterValue = null) => {
    let filteredData = participants;

    if (filterKey && filterValue) {
      filteredData = filteredData.filter((p) => p[filterKey] === filterValue);
    }

    return [...new Set(filteredData.map((p) => p[key]))].filter((value) => value);
  };

  return (
    <div className="list-peserta">

      {/* Filter and Export Section */}
      <div className="filter-section">
        <div className="filters">
          <select
            name="jenisKelamin"
            onChange={handleFilterChange}
            value={filters.jenisKelamin}
          >
            <option value="">Pilih Jenis Kelamin</option>
            {getUniqueValues("jenis_kelamin").map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          <select
            name="kabupaten"
            onChange={handleFilterChange}
            value={filters.kabupaten}
          >
            <option value="">Pilih Kabupaten</option>
            {getUniqueValues("kabupaten").map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          <select
            name="kecamatan"
            onChange={handleFilterChange}
            value={filters.kecamatan}
            disabled={!filters.kabupaten} // Disable if kabupaten not selected
          >
            <option value="">Pilih Kecamatan</option>
            {getUniqueValues("kecamatan", "kabupaten", filters.kabupaten).map(
              (value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              )
            )}
          </select>
          <select
            name="desa"
            onChange={handleFilterChange}
            value={filters.desa}
            disabled={!filters.kecamatan} // Disable if kecamatan not selected
          >
            <option value="">Pilih Desa</option>
            {getUniqueValues("desa", "kecamatan", filters.kecamatan).map(
              (value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              )
            )}
          </select>
          <button onClick={applyFilters}>Filter</button>
          <button onClick={resetFilters} className="btn-reset">
            Reset Filter
          </button>
        </div>
        <button className="btn-export" onClick={exportToExcel}>
          Export ke Excel
        </button>
      </div>

      {/* Table */}
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>Nama</th>
            <th>Jenis Kelamin</th>
            <th>Kabupaten</th>
            <th>Kecamatan</th>
            <th>Desa</th>
            <th>Handphone</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {filteredParticipants.length > 0 ? (
            filteredParticipants.map((p, index) => (
              <tr key={p.id}>
                <td>{index + 1}</td>
                <td>{p.nama}</td>
                <td>{p.jenis_kelamin}</td>
                <td>{p.kabupaten}</td>
                <td>{p.kecamatan}</td>
                <td>{p.desa}</td>
                <td>{p.handphone}</td>
                <td>{p.email}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">Tidak ada data.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ListPeserta;
