import React, { useState, useEffect } from "react";
import "../style/RTL.css";

const RTL = () => {
  const [rtlData, setRtlData] = useState([]); // Data RTL dari database
  const [showForm, setShowForm] = useState(false); // Toggle form popup
  const [formData, setFormData] = useState({
    kategori: "",
    rtl: "",
    tanggal_mulai: "",
    target: "",
    capaian: "",
    status: "Berjalan",
  });

  const [editRow, setEditRow] = useState(null); // Row yang sedang diedit

  // Fetch data RTL dari database
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://yac.respsourcing.com/rtl.php?method=GET");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setRtlData(result);
      } catch (error) {
        console.error("Error fetching RTL data:", error);
      }
    };

    fetchData();
  }, []);

  // Handle perubahan pada form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Submit data baru ke database
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Sending data:", formData);

    try {
      const response = await fetch("https://yac.respsourcing.com/rtl.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const newData = await response.json();

      setRtlData([...rtlData, newData]); // Tambahkan data baru ke tabel
      setShowForm(false); // Tutup form
      setFormData({
        kategori: "",
        rtl: "",
        tanggal_mulai: "",
        target: "",
        capaian: "",
        status: "Berjalan",
      });
    } catch (error) {
      console.error("Error submitting RTL data:", error);
      alert("Terjadi kesalahan saat mengirim data. Silakan coba lagi.");
    }
  };

  // Handle update data
  const handleUpdate = async (id) => {
    if (!editRow.status || editRow.status.trim() === "") {
      alert("Status tidak boleh kosong!");
      return;
    }
  
    console.log("Sending data for update:", editRow);
  
    try {
      const response = await fetch(`https://yac.respsourcing.com/rtl.php?id=${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editRow),
      });
  
      if (response.ok) {
        const updatedData = await response.json();
        console.log("Update successful, response data:", updatedData);
  
        setRtlData((prevData) =>
          prevData.map((row) => (row.id === id ? updatedData : row))
        );
        setEditRow(null); // Reset editRow setelah berhasil
      } else {
        alert("Error updating RTL data.");
      }
    } catch (error) {
      console.error("Error updating RTL data:", error);
    }
  };

  return (
    <div className="rtl-container">
      <button className="btn-primary" onClick={() => setShowForm(true)}>
        Tambah RTL
      </button>

      <table className="rtl-table">
        <thead>
          <tr>
            <th>No</th>
            <th>Kategori</th>
            <th>RTL</th>
            <th>Tanggal Mulai</th>
            <th>Target</th>
            <th>Capaian</th>
            <th>Status</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {rtlData.map((row, index) => (
            <tr key={row.id}>
              <td>{index + 1}</td>
              <td>
                {editRow?.id === row.id ? (
                  <select
                    value={editRow.kategori}
                    onChange={(e) =>
                      setEditRow({ ...editRow, kategori: e.target.value })
                    }
                  >
                    <option value="Pembibitan">Pembibitan</option>
                    <option value="Kompos">Kompos</option>
                    <option value="Okulator">Okulator</option>
                    <option value="Perbaikan Kebun">Perbaikan Kebun</option>
                    <option value="Jasa Servis">Jasa Servis</option>
                    <option value="Kelompok Kerja">Kelompok Kerja</option>
                    <option value="Lainnya">Lainnya</option>
                  </select>
                ) : (
                  row.kategori
                )}
              </td>
              <td>
                {editRow?.id === row.id ? (
                  <textarea
                    value={editRow.rtl}
                    onChange={(e) =>
                      setEditRow({ ...editRow, rtl: e.target.value })
                    }
                  />
                ) : (
                  row.rtl
                )}
              </td>
              <td>
                {editRow?.id === row.id ? (
                  <input
                    type="date"
                    value={editRow.tanggal_mulai}
                    onChange={(e) =>
                      setEditRow({ ...editRow, tanggal_mulai: e.target.value })
                    }
                  />
                ) : (
                  row.tanggal_mulai
                )}
              </td>
              <td>
                {editRow?.id === row.id ? (
                  <textarea
                    value={editRow.target}
                    onChange={(e) =>
                      setEditRow({ ...editRow, target: e.target.value })
                    }
                  />
                ) : (
                  row.target
                )}
              </td>
              <td>
                {editRow?.id === row.id ? (
                  <textarea
                    value={editRow.capaian}
                    onChange={(e) =>
                      setEditRow({ ...editRow, capaian: e.target.value })
                    }
                  />
                ) : (
                  row.capaian
                )}
              </td>
              <td>
                {editRow?.id === row.id ? (
                  <select
                  value={editRow.status || "Pilih Status"} // Pastikan ada nilai default
                  onChange={(e) =>
                    setEditRow({ ...editRow, status: e.target.value })
                  }
                >
                  <option value="">Pilih Status</option>
                  <option value="Berjalan">Berjalan</option>
                  <option value="Terencana">Terencana</option>
                  <option value="Selesai">Selesai</option>
                  <option value="Tertunda">Tertunda</option>
                </select>
                ) : (
                  row.status
                )}
              </td>
              <td>
                {editRow?.id === row.id ? (
                  <button
                    className="btn-secondary"
                    onClick={() => handleUpdate(row.id)}
                  >
                    Simpan
                  </button>
                ) : (
                  <button className="btn-edit" onClick={() => setEditRow(row)}>
                    Edit
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showForm && (
        <div className="rtl-form-popup">
          <form onSubmit={handleSubmit} className="rtl-form">
            <h2>Tambah RTL</h2>
            <select
              name="kategori"
              value={formData.kategori}
              onChange={handleChange}
              required
            >
              <option value="">Pilih Kategori</option>
              <option value="Pembibitan">Pembibitan</option>
              <option value="Kompos">Kompos</option>
              <option value="Okulator">Okulator</option>
              <option value="Perbaikan Kebun">Perbaikan Kebun</option>
              <option value="Jasa Servis">Jasa Servis</option>
              <option value="Kelompok Kerja">Kelompok Kerja</option>
              <option value="Lainnya">Lainnya</option>
            </select>
            <textarea
              name="rtl"
              placeholder="RTL"
              value={formData.rtl}
              onChange={handleChange}
              required
            />
            <input
              type="date"
              name="tanggal_mulai"
              value={formData.tanggal_mulai}
              onChange={handleChange}
              required
            />
            <textarea
              name="target"
              placeholder="Target"
              value={formData.target}
              onChange={handleChange}
              required
            />
            <textarea
              name="capaian"
              placeholder="Capaian"
              value={formData.capaian}
              onChange={handleChange}
              required
            />
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              required
            >
              <option value="Berjalan">Berjalan</option>
              <option value="Terencana">Terencana</option>
              <option value="Selesai">Selesai</option>
              <option value="Tertunda">Tertunda</option>
            </select>
            <div className="form-buttons">
              <button type="submit" className="btn-primary">
                Tambah
              </button>
              <button
                type="button"
                className="btn-secondary"
                onClick={() => setShowForm(false)}
              >
                Batal
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default RTL;
