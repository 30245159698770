import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IndexPage from "./pages/Index"; // Halaman awal
import Layout from "./components/Layout"; // Komponen Layout
import ListPeserta from "./components/ListPeserta"; // Komponen List Peserta
import Home from "./components/Home";
import BatchYAC from "./components/BatchYAC";
import RTL from "./components/RTL";
import SurveyMaker from "./components/SurveyMaker";
import SurveyList from "./components/Survey";
import RawData from "./components/RawData";
import ELearning from "./components/e-Learning";
import Sharing from "./components/SuccessSharing";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route untuk halaman Index */}
        <Route path="/" element={<IndexPage />} />

        {/* Layout membungkus semua halaman dengan sidebar dan navbar */}
        <Route path="/" element={<Layout />}>
          <Route path="home" element={<Home />} />
          <Route path="dashboard" element={<div>Dashboard</div>} />
          <Route path="list-peserta" element={<ListPeserta />} />
          <Route path="rtl" element={<RTL/>} />
          <Route path="survey-maker" element={<SurveyMaker/>} />
          <Route path="survey" element={<SurveyList/>} />
          <Route path="batch-yac" element={<BatchYAC />} />
          <Route path="raw-data" element={<RawData />} />
          <Route path="e-learning" element={<ELearning/>} />
          <Route path="sharing" element={<Sharing/>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
