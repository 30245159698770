import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../style/Home.css";

const Navbar = ({ title }) => {
  const [showSubNav, setShowSubNav] = useState(false);
  const navigate = useNavigate(); // Hook untuk navigasi

  // Fungsi logout
  const handleLogout = () => {
    localStorage.removeItem("user"); // Hapus data user dari localStorage
    navigate("/"); // Arahkan ke halaman index
  };

  return (
    <div className="navbar">
      <div className="navbar-title">
        <h1>{title}</h1> {/* Menampilkan judul berdasarkan rute */}
      </div>
      <div className="user-menu">
        <FaUserCircle
          className="user-icon"
          onClick={() => setShowSubNav(!showSubNav)}
        />
        {showSubNav && (
          <div className="user-subnav">
            <ul>
              <li onClick={() => navigate("/profile")}>Profil Saya</li>
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
