import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Untuk navigasi halaman
import "../style/Form.css";

const Form = ({ isVisible, onClose }) => {
  const [loginData, setLoginData] = useState({
    emailOrHandphone: "",
    password: "",
  });

  const navigate = useNavigate(); // Hook untuk navigasi

  // Handle perubahan input form
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  // Handle Submit Login
  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://yac.respsourcing.com/login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "login",
          emailOrHandphone: loginData.emailOrHandphone,
          password: loginData.password,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result.status === "success") {
        alert("Login successful!");
        console.log("User data:", result.participant);

        // Arahkan ke halaman Home
        navigate("/home");
        onClose(); // Tutup pop-up
      } else {
        alert(`Login failed: ${result.message}`);
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("An error occurred during login. Please try again.");
    }
  };

  if (!isVisible) return null; // Jika pop-up tidak terlihat, jangan render form

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
          &times;
        </button>
        <form className="form" onSubmit={handleLoginSubmit}>
          <h2>Login</h2>
          <input
            type="text"
            name="emailOrHandphone"
            placeholder="Email atau Nomor Handphone"
            value={loginData.emailOrHandphone}
            onChange={handleLoginChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Tanggal Lahir (YYYY-MM-DD)"
            value={loginData.password}
            onChange={handleLoginChange}
            required
          />
          <button type="submit" className="btn-primary">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
