import React, { useState, useEffect } from "react";
import "../style/Sharing.css";

const Sharing = () => {
  const [posts, setPosts] = useState([]);
  const [postData, setPostData] = useState({ content: "", file: null });
  const [isLoading, setIsLoading] = useState(false);

  // Fetch posts from the server
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch("https://yac.respsourcing.com/get_posts.php");
        const result = await response.json();
        if (result.status === "success") {
          setPosts(result.data);
        } else {
          console.error("Error fetching posts:", result.message);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  // Handle form submission
  const handlePostSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("content", postData.content);
    if (postData.file) formData.append("file", postData.file);

    try {
      const response = await fetch("https://yac.respsourcing.com/upload_post.php", {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      if (result.status === "success") {
        alert("Post created successfully!");
        setPosts((prev) => [result.data, ...prev]); // Tambahkan postingan baru ke array
        setPostData({ content: "", file: null }); // Reset form
      } else {
        alert(`Failed to create post: ${result.message}`);
      }
    } catch (error) {
      console.error("Error creating post:", error);
      alert("An error occurred while creating the post. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="sharing-container">

      {/* Post Form */}
      <form onSubmit={handlePostSubmit}>
        <textarea
          name="content"
          value={postData.content}
          onChange={(e) => setPostData({ ...postData, content: e.target.value })}
          placeholder="Write something..."
          required
        ></textarea>
        <input
          type="file"
          onChange={(e) => setPostData({ ...postData, file: e.target.files[0] })}
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Posting..." : "Post"}
        </button>
      </form>

      {/* Posts List */}
      <h2>Posts</h2>
      <div className="posts-list">
        {posts.map((post) => (
          <div key={post.id} className="post-item">
            <p>{post.content}</p>
            {post.file_path && (
              <img
                src={`https://yac.respsourcing.com/${post.file_path}`}
                alt="Post Attachment"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            )}
            <p className="post-date">{new Date(post.created_at).toLocaleString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sharing;
