import React, { useState, useEffect } from "react";
import "../style/HomeGalery.css";

const Home = () => {
  // Array gambar (gunakan path relatif dari folder src/assets)
  const images = [
    require("../assets/WhatsApp Image 2024-11-26 at 22.32.06.jpeg"),
    require("../assets/WhatsApp Image 2024-11-26 at 22.32.08.jpeg"),
    require("../assets/WhatsApp Image 2024-11-26 at 22.32.11 (1).jpeg"),
    require("../assets/WhatsApp Image 2024-11-26 at 22.32.11.jpeg"),
    require("../assets/WhatsApp Image 2024-11-26 at 22.32.12.jpeg"),
    require("../assets/WhatsApp Image 2024-11-26 at 22.32.17.jpeg"),
    require("../assets/WhatsApp Image 2024-11-26 at 22.32.19.jpeg"),
    require("../assets/WhatsApp Image 2024-11-26 at 22.34.20.jpeg"),
    require("../assets/WhatsApp Image 2024-11-26 at 22.34.23.jpeg"),
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Fungsi untuk berganti gambar otomatis setiap 3 detik
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval); // Membersihkan interval saat komponen di-unmount
  }, [images.length]);

  // Fungsi untuk navigasi manual ke gambar sebelumnya
  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Fungsi untuk navigasi manual ke gambar berikutnya
  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="home-container">
      <h2>Selamat Datang di Home</h2>
      <p>Galeri foto kami:</p>

      {/* Galeri Foto */}
      <div className="gallery">
        <button className="gallery-button prev" onClick={prevSlide}>
          &#8249; {/* Panah ke kiri */}
        </button>
        <img
          src={images[currentImageIndex]}
          alt={`Slide ${currentImageIndex + 1}`}
          className="gallery-image"
        />
        <button className="gallery-button next" onClick={nextSlide}>
          &#8250; {/* Panah ke kanan */}
        </button>
      </div>
    </div>
  );
};

export default Home;
