import React, { useState, useEffect } from "react";
import "../style/E-Learning.css";

const Elearning = () => {
  const [materials, setMaterials] = useState([]);
  const [fileData, setFileData] = useState({
    title: "",
    description: "",
    file: null,
  });
  const [isUploading, setIsUploading] = useState(false); // Untuk indikator loading
  const [errorMessage, setErrorMessage] = useState(""); // Untuk pesan error
  const [selectedMaterial, setSelectedMaterial] = useState(null); // State untuk modal preview

  // Fetch materials from the server
  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const response = await fetch("https://yac.respsourcing.com/get_materials.php");
        const result = await response.json();
        if (result.status === "success") {
          setMaterials(result.data);
        } else {
          console.error("Error fetching materials:", result.message);
        }
      } catch (error) {
        console.error("Error fetching materials:", error);
      }
    };

    fetchMaterials();
  }, []);

  // Validasi file
  const validateFile = (file) => {
    const allowedExtensions = ["pdf", "docx", "xlsx", "pptx"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!validateFile(file)) {
      setErrorMessage("Invalid file type. Only PDF, DOCX, XLSX, and PPTX are allowed.");
      setFileData({ ...fileData, file: null });
    } else {
      setErrorMessage("");
      setFileData({ ...fileData, file });
    }
  };

  // Handle input change for title and description
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFileData({ ...fileData, [name]: value });
  };

  // Handle file upload
  const handleFileUpload = async (e) => {
    e.preventDefault();

    if (isUploading) return; // Prevent multiple submits
    if (!fileData.file) {
      alert("Please select a valid file.");
      return;
    }

    setIsUploading(true); // Start loading
    const formData = new FormData();
    formData.append("title", fileData.title);
    formData.append("description", fileData.description);
    formData.append("file", fileData.file);

    try {
      const response = await fetch("https://yac.respsourcing.com/upload_material.php", {
        method: "POST",
        body: formData,
      });

      const textResponse = await response.text(); // Respons teks mentah
      console.log("Raw Response:", textResponse);

      const result = JSON.parse(textResponse); // Parse ke JSON
      if (result.status === "success") {
        alert("File uploaded successfully!");
        setMaterials((prev) => [...prev, result.data]); // Tambah material baru ke state
        setFileData({ title: "", description: "", file: "" }); // Reset form
      } else {
        alert(`Upload failed: ${result.message}`);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("An error occurred during file upload. Please try again.");
    } finally {
      setIsUploading(false); // Stop loading
    }
  };

  // Handle delete material
  const handleDeleteMaterial = async (id) => {
    if (!window.confirm("Are you sure you want to delete this material?")) return;

    try {
      const response = await fetch(`https://yac.respsourcing.com/delete_material.php?id=${id}`, {
        method: "DELETE",
      });

      const result = await response.json();
      if (result.status === "success") {
        alert("Material deleted successfully.");
        setMaterials((prev) => prev.filter((material) => material.id !== id));
      } else {
        alert(`Failed to delete material: ${result.message}`);
      }
    } catch (error) {
      console.error("Error deleting material:", error);
    }
  };

  const handleViewMaterial = (material) => {
    setSelectedMaterial(material);
  };

  // Close preview modal
  const handleClosePreview = () => {
    setSelectedMaterial(null);
  };

  return (
    <div className="elearning-container">

      {/* File Upload Section */}
      <form onSubmit={handleFileUpload}>
        <div>
          <label>Title</label>
          <input
            type="text"
            name="title"
            value={fileData.title}
            onChange={handleInputChange}
            placeholder="Enter file title"
            required
          />
        </div>
        <div>
          <label>Description</label>
          <textarea
            name="description"
            value={fileData.description}
            onChange={handleInputChange}
            placeholder="Enter file description"
          />
        </div>
        <div>
          <label>File</label>
          <input type="file" onChange={handleFileChange} required />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
        <button type="submit" disabled={isUploading}>
          {isUploading ? "Uploading..." : "Upload"}
        </button>
      </form>

      {/* Materials List */}
      <h2>List of Materials</h2>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>File</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {materials.map((material) => (
            <tr key={material.id}>
              <td>{material.title}</td>
              <td>{material.description}</td>
              <td>
                <button onClick={() => handleViewMaterial(material)}>View</button>
                {" "}|{" "}
                <a
                  href={`https://yac.respsourcing.com/${material.file_path}`}
                  download
                >
                  Download
                </a>
              </td>
              <td>
                <button onClick={() => handleDeleteMaterial(material.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Preview Modal */}
      {selectedMaterial && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={handleClosePreview}>
              &times;
            </button>
            <iframe
              src={`https://yac.respsourcing.com/${selectedMaterial.file_path}`}
              title={selectedMaterial.title}
              width="100%"
              height="500px"
            />
          </div>
        </div>
        )}
    </div>
  );
};

export default Elearning;
