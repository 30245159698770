import React from "react";
import Hero from "../components/Hero";
import Form from "../components/Register";
import "../style/Index.css";

const IndexPage = () => {
  return (
    <div>
      <Hero />
      <Form />
    </div>
  );
};

export default IndexPage;
