import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx"; // Import library untuk export ke Excel
import "../style/RawData.css";

const RawData = () => {
  const [surveys, setSurveys] = useState([]); // List of surveys
  const [selectedSurveyId, setSelectedSurveyId] = useState(null); // Selected survey ID
  const [tableData, setTableData] = useState([]); // Data for the table

  // Fetch the list of surveys
  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        const response = await fetch("https://yac.respsourcing.com/get_survey.php");
        const data = await response.json();
        console.log("Surveys API Response:", data);

        if (data.status === "success") {
          setSurveys(data.data);
        } else {
          console.error("Failed to fetch surveys:", data.message);
        }
      } catch (error) {
        console.error("Error fetching surveys:", error);
      }
    };

    fetchSurveys();
  }, []);

  // Fetch raw data for the selected survey
  const fetchRawData = async (surveyId) => {
    try {
      const response = await fetch(`https://yac.respsourcing.com/get_raw_data.php?survey_id=${surveyId}`);
      const data = await response.json();
      console.log("Raw API Response:", data);

      if (data.status === "success") {
        const processedData = processData(data.responses, data.questions);
        console.log("Processed Data for Table:", processedData);
        setTableData(processedData);
      } else {
        console.error("Failed to fetch raw data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching raw data:", error);
    }
  };

  // Process raw data into table format
  const processData = (responses, questions) => {
    const groupedByTimestamp = responses.reduce((acc, response) => {
      const timestamp = response.created_at;
      if (!acc[timestamp]) {
        acc[timestamp] = {}; // Initialize a row
      }
      const question = questions.find((q) => q.id === response.question_id);
      if (question) {
        acc[timestamp][question.label] = response.answer; // Map question label to answer
      }
      return acc;
    }, {});

    // Convert grouped data into an array for rendering
    return Object.values(groupedByTimestamp);
  };

  // Handle survey selection
  const handleSurveySelection = (event) => {
    const surveyId = event.target.value;
    setSelectedSurveyId(surveyId);
    setTableData([]); // Clear table data
    fetchRawData(surveyId); // Fetch data for selected survey
  };

  // Function to export table data to Excel
  const exportToExcel = () => {
    if (tableData.length === 0) {
      alert("Tidak ada data untuk diekspor!");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Raw Data");
    XLSX.writeFile(workbook, `Raw_Data_Survey_${selectedSurveyId}.xlsx`);
  };

  return (
    <div className="container">
      <p>Pilih jenis survei untuk melihat data hasilnya:</p>

      {/* Dropdown for selecting survey */}
      <select onChange={handleSurveySelection} value={selectedSurveyId || ""}>
        <option value="" disabled>
          Pilih Survei
        </option>
        {surveys.map((survey) => (
          <option key={survey.id} value={survey.id}>
            {survey.survey_title}
          </option>
        ))}
      </select>

      {/* Export to Excel Button */}
      <button onClick={exportToExcel} disabled={tableData.length === 0}>
        Export ke Excel
      </button>

      {/* Table to display raw data */}
      <table>
        <thead>
          <tr>
            {tableData.length > 0 &&
              Object.keys(tableData[0]).map((key) => (
                key !== "Timestamp" && <th key={key}>{key}</th> // Exclude "Timestamp"
              ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              {Object.entries(row).map(
                ([key, value], idx) =>
                  key !== "Timestamp" && <td key={idx}>{value || "-"}</td> // Exclude "Timestamp"
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RawData;
