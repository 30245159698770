import React, { useState } from "react";
import RegistrationForm from "./Pendaftaran";
import Form from "./Register";
import "../style/Global.css";

const Hero = () => {
  const [showForm, setShowForm] = useState(false); // State untuk mengontrol tampilan form
  const [showLogin, setShowLogin] = useState(false); // State untuk menampilkan pop-up login

  const handleLoginClick = () => {
    setShowLogin(true); // Tampilkan pop-up login
  };

  const handleCloseLogin = () => {
    setShowLogin(false); // Tutup pop-up login
  };

  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Welcome to Youth Agripreneur Camp!</h1>
        <h2>Saatnya generasi muda berinovasi di bidang pertanian</h2>
        <p>Apakah Anda berusia 18-35 tahun dan ingin berinovasi di bidang pertanian?</p>

        {/* Tambahkan paragraf kriteria peserta */}
        <div className="criteria">
          <h3>Kriteria Peserta:</h3>
          <ul>
            <li>Berusia 18-35 Tahun</li>
            <li>Mampu membaca dan menulis; sehat secara fisik dan mental</li>
            <li>Memiliki smartphone</li>
            <li>Didukung oleh orang tua/keluarga</li>
            <li>Berkomitmen mengikuti seluruh rangkaian kegiatan</li>
            <li>
              Siap menerima pendampingan dan memiliki keinginan untuk berkembang
              serta berbagi pengetahuan demi kemajuan dan kesejahteraan bersama
            </li>
          </ul>
        </div>

        <div className="hero-buttons">
          {/* Tombol untuk menampilkan form pendaftaran */}
          <button
            className="btn-primary"
            onClick={() => setShowForm(!showForm)} // Toggle tampilan form
          >
            {showForm ? "Tutup Formulir" : "Daftar Sekarang!"}
          </button>
          <button className="btn-primary" onClick={handleLoginClick}>
            Sudah Menjadi Peserta YAC? Klik Untuk Login
          </button>
        </div>
      </div>

      {/* Tampilkan form di bawah Hero Section */}
      {showForm && (
        <div className="registration-form-container">
          <RegistrationForm />
        </div>
      )}
      {/* Render pop-up login */}
      {showLogin && <Form isVisible={showLogin} onClose={handleCloseLogin} />}
    </section>
  );
};

export default Hero;
