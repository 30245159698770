import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Outlet, useLocation } from "react-router-dom"; // Import useLocation untuk mendapatkan rute saat ini
import "../style/Home.css";

const Layout = () => {
  const userName = "John Doe"; // Nama user untuk ditampilkan di navbar
  const location = useLocation(); // Mendapatkan informasi rute saat ini

  // Mapping path ke judul navbar
  const titles = {
    "/home": "Galery Foto",
    "/dashboard": "Dashboard",
    "/list-peserta": "List Peserta",
    "/rtl": "Rencana Tindak Lanjut",
    "/survey-maker": "Buat Pertanyaan Survey",
    "/survey": "Survey",
    "/batch-yac": "Batch YAC",
    "/raw-data": "Raw Data",
    "/e-learning": "e-Learning Materials",
    "/sharing": "Sharing & Discussion",
  };

  // Menentukan judul berdasarkan rute saat ini
  const currentTitle = titles[location.pathname] || "Dashboard";

  return (
    <div className="home">
      {/* Sidebar tetap */}
      <Sidebar />
      <div className="content">
        {/* Navbar tetap */}
        <Navbar userName={userName} title={currentTitle} />
        {/* Konten halaman ditampilkan di Outlet */}
        <div className="content-body">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
