import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaHome, FaTachometerAlt, FaList, FaClipboardList, FaPoll, FaBox, FaDatabase, FaBook, FaCrown} from "react-icons/fa";
import "../style/Home.css";


const Sidebar = () => {
  const [isSidebarOpen] = useState(true); // State untuk mengontrol toggle sidebar

  return (
    <>
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        <img src={require("../assets/Logo.jpeg")} alt="Logo" />
        <ul>
          <li>
            <Link to="/home">
              <FaHome className="sidebar-icon" /> <span>Home</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard">
              <FaTachometerAlt className="sidebar-icon" /> <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/list-peserta">
              <FaList className="sidebar-icon" /> <span>List Peserta</span>
            </Link>
          </li>
          <li>
            <Link to="/rtl">
              <FaClipboardList className="sidebar-icon" /> <span>RTL</span>
            </Link>
          </li>
          <li>
            <Link to="/e-learning">
              <FaBook className="sidebar-icon" /> <span>e-Learning</span>
            </Link>
          </li>
          <li>
            <Link to="/sharing">
              <FaCrown className="sidebar-icon" /> <span>Success Sharing</span>
            </Link>
          </li>
          <li>
            <Link to="/survey">
              <FaPoll className="sidebar-icon" /> <span>Survey</span>
            </Link>
          </li>
          <li>
            <Link to="/survey-maker">
              <FaPoll className="sidebar-icon" /> <span>Buat Survey</span>
            </Link>
          </li>
          <li>
            <Link to="/batch-yac">
              <FaBox className="sidebar-icon" /> <span>Batch YAC</span>
            </Link>
          </li>
          <li>
            <Link to="/raw-data">
              <FaDatabase className="sidebar-icon" /> <span>Raw Data</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
